/**
 * Copyright 2023 LiveKit, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ReactElement, useEffect, useState } from 'react';
import _ from "lodash";
import { ConnectionState, RoomEvent, Track } from 'livekit-client';
import {
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useRoomContext,
  useTracks,
} from '@livekit/components-react';
import EgressHelper from '@livekit/egress-sdk';
import * as sentry from '@sentry/react';
import SingleSpeakerLayout from './SingleSpeakerLayout';
import SpeakerLayout from './SpeakerLayout';
import { eventLogAction } from '../../utils/mixpanelActions';
import { mixpanelEvents } from '../../utils/mixpanelEvents';

interface RoomPageProps {
  url: string;
  token: string;
  layout: string;
}

export function getURLParam(name: string): string | null {
  // @ts-ignore
  const query = new URLSearchParams(window.location.search);
  return query.get(name);
}

export default function RoomPage({ url, token, layout }: RoomPageProps) {
  const [error, setError] = useState<Error>();

  if (!url || !token) {
    return <div className="error">missing required params url and token</div>;
  }

  return (
    <LiveKitRoom serverUrl={url} token={token} onError={setError}>
     <CompositeTemplate layout={layout} error={error} />
    </LiveKitRoom>
  );
}

interface CompositeTemplateProps {
  layout: string;
  error: any;
}

function CompositeTemplate({ layout: initialLayout,error }: CompositeTemplateProps) {
  const room = useRoomContext();
  const [layout, setLayout] = useState(initialLayout);
  const [hasScreenShare, setHasScreenShare] = useState(false);
  const screenshareTracks = useTracks([Track.Source.ScreenShare], {
    onlySubscribed: true,
  });

  const sessionId = _.get(room,"roomInfo.name","")

  useEffect(() => {
    sentry.setTag('room_name',sessionId)
    if (room && sessionId) {
      eventLogAction({
        event: _.get(mixpanelEvents, 'MEETING_RECORDING_TEMPLATE_LOADED'),
        payload: { sessionId },
      });

      EgressHelper.setRoom(room);

      // Egress layout can change on the fly, we can react to the new layout
      // here.
      EgressHelper.onLayoutChanged((newLayout) => {
        setLayout(newLayout);
      });

      room.once(RoomEvent.ParticipantConnected, startRecording);

    }
  }, [room,sessionId]);

  useEffect(() => {
    if (screenshareTracks.length > 0 && screenshareTracks[0].publication) {
      setHasScreenShare(true);
    } else {
      setHasScreenShare(false);
    }
  }, [screenshareTracks]);

  const startRecording=()=>{
    EgressHelper.startRecording();
    eventLogAction({
      event: _.get(mixpanelEvents, 'MEETING_TEMPLATE_RECORDING_STARTED'),
      payload: { sessionId },
    });
  }

  const allTracks = useTracks(
    [Track.Source.Camera, Track.Source.ScreenShare, Track.Source.Unknown,Track.Source.Microphone],
    {
      onlySubscribed: true,
    },
  );

  if (error) {
    sentry.captureException(error);
    if (_.includes(error?.message, "could not establish pc connection")) EgressHelper?.endRecording()
    return <div className="error">{error?.message}</div>
  }

  let interfaceStyle = 'dark';
  if (layout.endsWith('-light')) {
    interfaceStyle = 'light';
  }

  let containerClass = 'roomContainer';
  if (interfaceStyle) {
    containerClass += ` ${interfaceStyle}`;
  }

  // determine layout to use
  let main: ReactElement = <></>;
  let effectiveLayout = layout;
  if (hasScreenShare && layout.startsWith('grid')) {
    effectiveLayout = layout.replace('grid', 'speaker');
  }
  const references = _.groupBy(allTracks, 'participant.identity')

  if (room.state !== ConnectionState.Disconnected) {
    if (effectiveLayout.startsWith('speaker') && _.get(Object.keys(references),"length") > 1) {
      main = <SpeakerLayout tracks={allTracks} participantTracks={references} sessionId={sessionId}  />;
    } else if (effectiveLayout.startsWith('single-speaker') || _.get(Object.keys(references),"length") === 1) {
      main = <SingleSpeakerLayout tracks={allTracks} participantTracks={references} sessionId={sessionId} />;
    } else {
      main = (
        <GridLayout tracks={allTracks}>
          <ParticipantTile />
        </GridLayout>
      );
    }
  }

  return (
    <div className={containerClass}>
      {main}
      <RoomAudioRenderer />
    </div>
  );
}
